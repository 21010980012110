import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import user_api from "../../ApiCallExpress/user_api";
import globalStore from "../../shared/globalStore";
import shared from "../../shared/shareStore";
import config, { s3_bucket } from "../../config";

export default function Biglogo(props) {
  const apiURL = window.location.origin.includes("http://localhost:")
    ? "http://localhost:4000"
    : window.location.origin.replace("3000", "4000");

  const [logo, setLogo] = useState("");
  var link = props.link;
  var style = "";
  if (link === "/") {
    style = "flex justify-end ";
    //check if being used in a iframe
    if (window.location !== window.parent.location) {
      link = "";
    }
  } else {
    style = "flex justify-center sm:pt-4 md:pt-  ";
  }

  function IfPartner() {
    return config.dynamic
      ? !window.location.origin.includes(config.main_origin_build)
      : !window.location.origin.includes(config.main_origin_dev) &&
          config.checkOrigin;
  }

  useEffect(() => {
    if (globalStore.getState().session.user.login_partner) {
      setLogo(
        `https://${s3_bucket}.s3.amazonaws.com/` +
          globalStore.getState().session.user.login_partner.partnerLogo
      );
    } else if (
      config.dynamic
        ? !config.main_origin_build.includes(window.location.origin)
        : !config.main_origin_dev.includes(window.location.origin) &&
          config.checkOrigin
    ) {
      user_api
        .get_partner_by_origin(window.location.origin)
        .then((res) => {
          setLogo(`https://${s3_bucket}.s3.amazonaws.com/${res.partnerLogo}`);
        })
        .catch((error) => {
          console.log("error", error);
          setLogo("");
        });
    }
  }, []);
  // console.log("logo", logo);

  // logo.url = apiURL + "/userExpress/get_partner_logo_file/?file_url=" + res.url;

  return (
    <div className={style}>
      <a
        href="/"
        className="font-Gilroy-blk text-violet-100 text-5xl lg:text-7xl  text-center"
      >
        {!IfPartner() ? (
          <div>
            <p>WIZ</p>
            <p className="-mt-2 text-lg lg:text-2xl font-Gilroy-l text-center">
              Learner
            </p>
          </div>
        ) : IfPartner() && logo === "" ? (
          <div className=" text-transparent">
            <p>WIZ</p>
            <p className="-mt-2 text-lg lg:text-2xl font-Gilroy-l text-center">
              Learner
            </p>
          </div>
        ) : (
          <div>
            <img src={logo} alt="logo" className="w-40 lg:w-48" />
          </div>
        )}
      </a>
    </div>
  );
}
