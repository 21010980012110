
var config = {};
//true to use build entry and false to use dev entry
config.dynamic = true;
config.host = "localhost";
config.googleAPI = "AIzaSyBq90_zk-yfdcFngDnQkdcvX_6i43DpVgw";

config.wizrobotics_react_name = "wizrobotics_react";
config.wizrobotics_react_entryBuild = "/wizrobotics_react";
config.wizrobotics_react_entryDev = "//localhost:3001";

config.wiz_chat_name = "wiz_chat";
config.wiz_chat_entryBuild = "/wizrobotics_chat";
config.wiz_chat_entryDev = "//localhost:3002";

config.wiz_public_name = "wiz_public";
config.wiz_public_entryBuild = "/wizrobotics_public";
config.wiz_public_entryDev = "//localhost:3003";

config.s3_bucket = "wiz-robotics-assets";
config.s3_bucket_region = "us-east-2";
config.s3_bucket_url = "https://wiz-robotics-assets.s3.us-east-2.amazonaws.com/";



//mac: https://kinsta.com/knowledgebase/edit-mac-hosts-file/ to edit hosts file
config.checkOrigin = true;
config.main_origin_dev = "http://localhost:3000";
config.main_origin_build = "https://learn.wizrobotics.com";


module.exports = config;