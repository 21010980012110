import React, { useState, useEffect } from "react";
import user_api from "../../ApiCallExpress/user_api.js";
import styles from "../../pages/userAccessPage/StylingForUseraccess.js";
import Logo from "../logo/biglogo.js";

import { useNavigate } from "react-router-dom";

export default function ForgotUsername() {
  useEffect(() => {
    // document.title = "Wiz-Forgot Username";

    const title = document.querySelector("title");
    title.innerHTML = title.innerHTML + " - Forgot Username";
  }, []);
  const history = useNavigate();
  const [user, setUser] = useState({ email: "" });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  var loadScreen = (
    <img
      class="w-10 h-10 animate-spin"
      src="https://www.svgrepo.com/show/70469/loading.svg"
      alt="Loading icon"
    />
  );

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const [inputError, setInputError] = useState({
    email: "",
  });

  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          //check if value is email or not
          if (!value) {
            stateObj[name] = "Please enter Email.";
          } else if (!value.includes("@") || !value.includes(".")) {
            stateObj[name] = "Please enter a valid Email.";
          }

          break;
      }

      return stateObj;
    });
  };

  const handleMessage = (event) => {
    setMessage("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputError.email === "") {
      user_api
        .forgotusername(user.email)
        .then((res) => {
          if (res.error !== undefined) {
            setMessage(res.error);
          } else {
            setMessage("Email sent successfully");
            setLoading(true);
            setTimeout(() => {
              history("/");
            }, 3000);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      setMessage("Please enter valid email");
    }
  };
  var message_style;
  if (message === "Email sent successfully") {
    message_style = (
      <div>
        <div className="pt-2 h-12 flex max-w-sm alert alert-success">
          <div className="justify-start">
            <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
              {message + " "}
            </p>
          </div>
          <button onClick={handleMessage} className="flex-none pt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>

        {loading !== false && loadScreen}
      </div>
    );
  } else {
    message_style = (
      <div className="pt-2 h-12 flex max-w-sm alert alert-error">
        <div className="justify-start">
          <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
            {message + " "}
          </p>
        </div>
        <button onClick={handleMessage} className="flex-none pt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center my-auto pt-4 sm:px-8 md:px-16 lg:px-16 xl:px-64 ">
      <Logo link="/" />
      <p
        className={
          "text-left sm:pb-2 md:pb-8 lg:pb-16 font-Gilroy-b text-dsblue-100" +
          styles.title_size_dynamic
        }
      >
        Forget Username?
        <p className={styles.Atag_size_dynamic + message_style}>
          {message !== "" && message_style}
        </p>
      </p>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <div className="flex flex-col pt-4">
          <label for="username" className={styles.input_lable}>
            Enter Your Email.
          </label>
          <input
            type="email"
            name="email"
            onChange={onInputChange}
            onBlur={validateInput}
            // placeholder="username"
            className={styles.input_box}
          />
        </div>
        <div className={styles.input_error}>
          {inputError.email && <span className="">{inputError.email}</span>}
        </div>
        <p className="text-right">
          <a
            href="/forgotpassword"
            className={
              "pt-2 text-right text-violet-90" + styles.Atag_size_dynamic
            }
          >
            Forgot Password?
          </a>
        </p>
        <div className="flex justify-end pt-4">
          <button className={styles.btn_size_dynamic + " w-full"} type="submit">
            Email Me
          </button>
        </div>
      </form>
    </div>
  );
}
