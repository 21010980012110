import React, { Component, useState, useEffect } from "react";
import Login from "../../component/login/index";
import Signup from "../../component/signup/index";
import ForgotPassword from "../../component/forgotPassword/index";
import ForgotUsername from "../../component/forgotUsername/index";
import ResetPassword from "../../component/resetPassword/resetPassword";
import Logo from "../../component/logo/biglogo";
import axios from "axios";

import {
  arduino0,
  chip0,
  chip1,
  coding0,
  coding1,
  robot0,
  robot1,
} from "../../Asset/img/userAccessPage";
import color from "../../corlorConfig";
import config from "../../config";
import PartnerSignup from "../../component/partnerSignup/partnerSignup";
import user_api from "../../ApiCallExpress/user_api";
import usertype from "../../usertype";

export default function UserAccessPage(props) {
  const [loading, setLoading] = useState(true);
  const ifPartner = () => {
    return config.dynamic
      ? !config.main_origin_build.includes(window.location.origin)
      : !config.main_origin_dev.includes(window.location.origin) &&
          config.checkOrigin;
  };
  var rightsideComponent;
  if (props.name === "login") {
    rightsideComponent = <Login />;
  } else if (props.name === "signup") {
    // rightsideComponent = <Signup />;
    rightsideComponent = ifPartner() ? <PartnerSignup /> : <Signup />;
  } else if (props.name == "forgotpassword") {
    rightsideComponent = <ForgotPassword />;
  } else if (props.name == "forgotusername") {
    rightsideComponent = <ForgotUsername />;
  } else if (props.name == "reset") {
    rightsideComponent = <ResetPassword />;
  }

  // const [image, setImage] = useState(arduino0);
  var imgList = [arduino0, chip0, chip1, coding0, coding1, robot0, robot1];

  useEffect(() => {
    //if site is being used by iframe then set the color to violet
    if (window.location === window.parent.location) {
      color("ThemeViolet");
    } else {
      color("ThemeNeutral");
    }
    if (
      loading &&
      (props.name === "login" ||
        props.name === "signup" ||
        props.name === "forgotpassword" ||
        props.name === "forgotusername")
    ) {
      user_api
        .session_check()
        .then((res) => {
          if (res.validUser) {
            window.location.href = usertype.studentView.includes(
              res.user.usertype
            )
              ? "/dashboard"
              : "/classrooms";
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <div className="w-full flex flex-wrap justify-center ">
      {!ifPartner() && (
        <div className="md:w-1/2 w-full flex flex-col shadow-2xl">
          <img
            className="md:h-screen md:w-screen w-0 h-0 object-cover "
            // src={checkIfImageIsWorking()}
            src={imgList[Math.floor(Math.random() * imgList.length)]}
            // src={
            //   "https://source.unsplash.com/random/portrait/?" + randomKeyWords()
            // }
          />
        </div>
      )}
      <div
        className={
          ifPartner()
            ? "w-full flex flex-col px-5 lg:px-8 bg-white"
            : "w-full md:w-1/2 flex flex-col px-5 lg:px-8 bg-white"
        }
      >
        {/* <Logo link="/"/> */}
        {rightsideComponent}
      </div>
    </div>
  );
}
